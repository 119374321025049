/* make the customizations */
$dark: #000;
$light: #fff;


  $theme-colors: (
  "primary":    $dark,
  "secondary":  $light,
  "success":    $dark,
  "info":       $dark,
  "warning":    $light,
  "danger":     $dark,
  "light":      $light, 
  "dark":       $dark
);




/* import bootstrap to set changes */
@import '~bootstrap/scss/bootstrap';

:root {
  --bs-body-bg : black;
  --bs-tertiary-bg-rgb : black;
  --bs-heading-color : white;
  --bs-body-color: white;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {


  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* The following block can be included in a custom.scss */



